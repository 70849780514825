export default defineNuxtRouteMiddleware((_to, _from) => {
  const { user } = useUser()

  if (!user.value) {
    return navigateTo('/login')
  }

  if (
    !user.value?.roles?.includes('ROLE_SUPER_ADMIN')
    && !user.value?.roles?.includes('ROLE_OPS_ADMIN')
    && !user.value?.roles?.includes('ROLE_PRODUCT_ADMIN')
  ) {
    return navigateTo('/login')
  }
})
